import React, { createContext } from 'react'
import styled from 'styled-components'


const StyledSection = styled.section`
  background-color: ${({ dark, theme }) =>
  dark === true ? 'transparent' : 'white'
  };
`

export const SectionContext = createContext({ dark: false })

const Section = ({ dark, ...props }) => {
  return (
    <SectionContext.Provider value={{ dark }}>
      <StyledSection dark={dark} {...props} />
    </SectionContext.Provider>
  )
}



export default Section