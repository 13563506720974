import React from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"

const Image2 = styled.div`
  background-image: url(${require("assets/images/home/arkeo-man-section2.png")});
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-end;
  max-width: 740px;
  min-height: 500px;
  margin: -600px auto 0;
  padding: 0 2rem;
`

const ButtonSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
`

const Button = styled.button`
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.family.bebas};
  font-size: 40px;
  color: ${({ theme }) => theme.colors.fonts.title};
  border: 3px solid ${({ theme }) => theme.colors.fonts.title};
  border-radius: 0.66em;
  padding: 0.33em 1em;
`

const Card = styled.div`
  position: relative;
  transform: scale(10%);

  &:after {
    font-size: 2rem;
    font-family: ${({ theme }) => theme.font.family.circular};
    font-weight: 500;
    color: ${({ theme }) => theme.colors.background.dark};
    content: "We all have mental fitness";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 26%;
    right: 13%;
    left: 2%;
    bottom: 9%;
  }
`

const CustomCard = () => {
  return (
    <Card>
      <img src={require("assets/images/home/arkeo-woman-dancing.png")} />
    </Card>
  )
}

const CardContainer = styled.div`
  width: 33%;
  display: flex;
`

const TextWithImageSection = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
  margin: 0 -50px;
  padding: 0 50px;
  flex-wrap: wrap;
`

const ImageContainer = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
`

const TextContainer = styled.div`
  flex: 1 1 auto;
  display: column;
  justify-content: flex-start;
`

const PhoneSectionImage = styled.img`
  max-height: 400px;
  margin: 50px 20px;
`

const ComputerImage = styled.img`
  max-height: 400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    max-height: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    max-height: 200px;
  }
`

const ComputerImageContainer = styled.div`
  display: flex;
  margin-top: -300px;
  justifycontent: flex-start;
  max-width: 1440px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: -200px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    margin-top: -100px;
  }
`

const ConditionsAndHowSection = () => (
  <Section>
    <Layout.SafeArea small>
      <ComputerImageContainer>
        <ComputerImage
          src={require("assets/images/organisations/arkeo-desktopdash.png")}
        />
      </ComputerImageContainer>
      <Text type="header">
        MENTAL HEALTH CONDITIONS ACCOUNT
        <br />
        FOR 12% OF WORKPLACE ABSENCES*
      </Text>
      <Text type="subheader">*OFFICE OF NATIONAL STATISTICS</Text>
      <TextWithImageSection>
        <TextContainer>
          <Text type="body">
            It’s your biggest concern and your biggest expense. The people who
            guide your organisation to success are your most valuable asset.
            Performance and that success, however you measure it, is guided by
            people’s abilities and how they work together as a team and how they
            cope with challenges or thrive.
          </Text>
          <Text type="body">
            Yes flexible working, incentives and perks can drastically reduce
            staff stress, but can you track the impact of a wellbeing
            initiative? Can you track performance and employee satisfaction
            accurately and at scale?
          </Text>
          <Text type="body">
            Workplace absenteeism through mental health issues costs the UK £38
            billion/year*. Where does that leave you?
          </Text>
          <Text type="body">*UK watchdog</Text>
        </TextContainer>
        <ImageContainer>
          <PhoneSectionImage
            src={require("assets/images/organisations/woman-suit-sunlight.png")}
          />
        </ImageContainer>
      </TextWithImageSection>
      <TextWithImageSection reverse>
        <TextContainer>
          <Text type="header">HOW HAVE WE GOT HERE?</Text>
          <Text type="body">
            You want your people to be happy, to work hard and be productive.
            Not only are you concerned about your bottom line but how teams can
            be looked after. We get that. It’s now time to be able to put a
            number to it.
          </Text>
          <Text type="body">
            Traditional ways to track performance and employee satisfaction,
            usually track one or the other. Rarely both. And if they do it’s a
            long winded process.
          </Text>
          <Text type="body">
            That’s what the Arkeo app gives you. As an HR director, CEO or COO,
            you can track your companies performance, their mental state and
            gauge how mentall fit your business is. To take on challenges, to
            grow and to achieve things you never thought were possible.
          </Text>
        </TextContainer>
        <ImageContainer>
          <PhoneSectionImage
            src={require("assets/images/organisations/woman-glasses.png")}
          />
        </ImageContainer>
      </TextWithImageSection>
    </Layout.SafeArea>
  </Section>
)

export default ConditionsAndHowSection
