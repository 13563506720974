import React from 'react'
import MainTemplate from 'templates/MainTemplate/MainTemplate'
import Organisations from '../components/Organisations'

const TeamPage = () => (
  <MainTemplate title="Arkeo - Organizations">
    <Organisations />
  </MainTemplate>
)

export default TeamPage
