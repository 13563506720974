import styled from "styled-components"

import Header from "components/Header/Header"
import Footer from "components/Footer/Footer"
import Main from "./Main"

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

Layout.Header = Header
Layout.Footer = Footer
Layout.Main = Main
Layout.SafeArea = styled.div`
  margin: 0 auto;
  max-width: ${({ small }) => (small ? "960px" : "1440px")};
  padding: 3rem;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 2rem;
  }
`

export default Layout
