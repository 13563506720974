import React from "react"
import styled from "styled-components"

const Form = styled.div`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
`

const Input = styled.input`
  background-color: #454545;
  padding: 15px;
  border: none;
  color: white;
  outline: none;
  margin: 0;

  font-family: ${({ theme }) => theme.font.family.circular};
  font-weight: 400;
  font-size: 1rem;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 50%;
    font-size: 14px;
  }
`

const Button = styled.button`
  background-image: linear-gradient(to right, #49cc5d, #9ae435);
  background-color: transparent;
  font-family: ${({ theme }) => theme.font.family.bebas};
  font-size: 22px;
  font-weight: 400;
  color: white;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border: none;
  cursor: pointer;
  padding: 0 20px 0 20px;
  margin: 0;

  @media (max-width: 480px) {
    font-size: 18px;
  }
`

const SendEmailForm = ({ value, onSubmit, onChange }) => (
  <Form>
    <Input
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder="enter your email..."
    ></Input>
    <Button onClick={onSubmit}>SUBMIT ></Button>
  </Form>
)

export default SendEmailForm
