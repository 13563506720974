import React, { useState } from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"
import SendEmailForm from "components/SendEmailForm"
import subscribeUser from "utils/subscribeUser"

const StyledTrackingSection = styled(Section)`
  background-image: url(${require("assets/images/organisations/arkeo-what-we-do-background.png")});
  background-size: cover;
  background-position: center;
  height: 600px;
`

const ContentPosition = styled.div`
  margin-top: 50px;
`

const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;

  margin-top: -400px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-top: -200px;
  }
`

const CardContainer = styled.div`
  flex: 1 0 100%;
  display: flex;
  justify-content: ${({ align }) => align || "center"};
  align-items: center;
  max-width: 960px;
  margin: 0 auto -100px;
  flex-wrap: wrap;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    margin-bottom: 50px;
    justify-content: center;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const StyledCardImage = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: top;
  height: 400px;
`
const StyledCardText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
`

const StyledCard = styled.div`
  flex: 0 0 auto;
  width: 280px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #454545;
  box-shadow: 0px 5px 20px 5px #0000004a;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    width: 75%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    width: 100%;
  }
`

const Card = ({ image, text, style }) => (
  <StyledCard style={style}>
    <StyledCardImage src={image} />
    <StyledCardText>{text}</StyledCardText>
  </StyledCard>
)

const ComingSoonText = styled(Text.Header)`
  max-width: 500px;
  font-size: 38px;

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 2rem;
  }
`

const WhatWeDoSection = () => {
  const [email, setEmail] = useState("")

  const handleSubmitEmail = async () => {
    if (await subscribeUser(email)) {
      setEmail("")
    }
  }

  return (
    <>
      <StyledTrackingSection dark>
        <Layout.SafeArea small>
          <ContentPosition align="flex-end">
            <Text
              type="header"
              style={{ fontSize: 62 }}
            >
              WHAT WE DO FOR YOUR BUSINESS
            </Text>
          </ContentPosition>
        </Layout.SafeArea>
      </StyledTrackingSection>
      <Section>
        <Layout.SafeArea small>
          <Cards>
            <CardContainer align="flex-start">
              <Card
                image={require("assets/images/organisations/card1.png")}
                text={
                  <Text dark type="body">
                    Bring in data lead training to support the main challenges
                    being tracked by staff
                  </Text>
                }
              />
            </CardContainer>
            <CardContainer align="flex-end">
              <Card
                image={require("assets/images/organisations/card2.png")}
                text={
                  <Text dark type="body">
                    Aim to reduce absenteeism due to ill mental health by 20%
                    and increase productivity and happiness and promote and
                    encourage positive mental fitness.
                  </Text>
                }
              />
            </CardContainer>
            <CardContainer align="space-between">
              <Card
                image={require("assets/images/organisations/card3.png")}
                text={
                  <Text dark type="body">
                    Key insights and learnings into behaviours and challenges to
                    better support and communicate with your staff.
                  </Text>
                }
              />
              <div>
                <ComingSoonText>
                  WE LAUNCH OUR B2B SERVICE APRIL 2020
                </ComingSoonText>
                <Text>
                  Sign up now to be part of the BETA to make your organisation mentally fit.
                </Text>
                <SendEmailForm
                  value={email}
                  onChange={setEmail}
                  onSubmit={handleSubmitEmail}
                />
              </div>
            </CardContainer>
          </Cards>
        </Layout.SafeArea>
      </Section>
    </>
  )
}

export default WhatWeDoSection
