import React from "react"
import styled from "styled-components"
import Layout from "./Layout"
import TitleSection from "./Organisations/TitleSection"
import ConditionsAndHowSection from "./Organisations/ConditionsAndHowSection"
import WhatWeDoSection from "./Organisations/WhatWeDoSection"

const Organisations = ({ siteTitle }) => (
  <Layout
    style={{
      overflowX: "hidden",
    }}
  >
    <Layout.Header />
    <Layout.Main>
      <TitleSection />
      <ConditionsAndHowSection />
      <WhatWeDoSection />
    </Layout.Main>
    <Layout.Footer />
  </Layout>
)

export default Organisations
