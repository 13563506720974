import React from 'react'
import styled from 'styled-components'
import { SectionContext } from './Section'


const Text = ({ type = 'body', ...props }) => {
  const Component = types[type.toLowerCase()]

  if (!Component) {
    console.warn('No such text type')
    return null
  }

  return (
    <SectionContext.Consumer>
      {({ dark }) => <Component dark={props.dark || dark} {...props} />}
    </SectionContext.Consumer>
  )
}

Text.Title = styled.h1`
  font-size: 7rem;
  font-family: ${({ theme }) => theme.font.family.bebas};
  margin: 0.33em 0;

  ${({ color }) => color ? `
    color: ${color}
  `: `
    background: -webkit-linear-gradient(#95e649, #49d16e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: 5rem;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    font-size: 3.5rem;
  }
`

Text.Body = styled.p`
  font-size: 1rem;
  max-width: 400px;
  font-family: ${({ theme }) => theme.font.family.circular};
  font-weight: 400;
  margin-bottom: 2em;
  color: ${({ dark, theme }) => dark ? 'white' : theme.colors.background.dark};
`

Text.Header = styled.h2`
  font-size: 3rem;
  font-family: ${({ theme }) => theme.font.family.bebas};
  margin: 0.33em 0;
  font-weight: 400;
  color: ${({ dark, theme }) => dark ? 'white' : theme.colors.background.dark};
`

Text.SubHeader = styled.h3`
font-size: 1.5rem;
font-family: ${({ theme }) => theme.font.family.bebas};
font-weight: 400;
margin: 0.33em 0;
color: ${({ dark, theme }) => dark ? 'white' : theme.colors.background.dark};
`

const types = Object.fromEntries(
  Object.entries(Text).map(([key, component]) => [key.toLowerCase(), component])
)

export default Text