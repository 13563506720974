async function subscribeUser(email) {
  const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  if (!emailRegExp.test(email)) {
    alert("This email is invalid. Enter correct email address.")
    return
  }
  const formData = new FormData()
  formData.append("email", email)

  return fetch("/subscribe-user.php", {
    method: "POST",
    body: formData,
  }).then(response => {
    if (response.status === 200) {
      alert("Successfully subscribed your email")
    } else {
      alert("There was a problem with subscribing your email")
    }
    return response.status === 200
  })
}

export default subscribeUser
