import React, { useState } from "react"
import styled from "styled-components"

import Layout from "components/Layout"
import Text from "components/Text"
import Section from "components/Section"
import SendEmailForm from "components/SendEmailForm"

import subscribeUser from "utils/subscribeUser"

const Image = styled.div`
  background-image: linear-gradient(
      rgba(37, 39, 40, 50%) 0%,
      rgba(37, 39, 40, 50%) 50%,
      rgb(37, 39, 40) 90%
    ),
    url(${require("assets/images/organisations/arkeo-abouttheapp-hero.png")});
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto 200px;
  padding: 40px 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    padding: 100px 0;
    margin: 0 auto 100px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
    padding: 0 0;
    margin: 0 auto 50px;
  }
`

const TitleSection = () => {
  const [email, setEmail] = useState("")

  const handleSubmitEmail = async () => {
    if (await subscribeUser(email)) {
      setEmail("")
    }
  }

  return (
    <Section dark>
      <Layout.SafeArea small>
        <Image>
          <Text
            type="title"
            color="white"
            style={{ fontWeight: "400", maxWidth: 600 }}
          >
            HELP YOUR STAFF FEEL BETTER. TEACH GOOD MENTAL FITNESS.
          </Text>
          <Text style={{ marginBottom: 25 }} type="SUBheader">
            SIGN UP FOR THE BETA LAUNCHING IN APRIL 2020
          </Text>
          <SendEmailForm
            value={email}
            onSubmit={handleSubmitEmail}
            onChange={setEmail}
          />
        </Image>
      </Layout.SafeArea>
    </Section>
  )
}

export default TitleSection
